// App.js
import React, { useState } from 'react';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const App = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleConfirmClick = () => {
    const urlSearchParams = new URLSearchParams(window.location.href.split('?')[1]);
    const api = urlSearchParams.get('api');
    const token = urlSearchParams.get('token');
    console.log(token)
    console.log(api)

    if (password === confirmPassword) {
      axios({
        method: "put",
        baseURL: api + "/auth/password-reset/new-password",
        data: {
          password,
          token
        }
      }).then(() => {
        setMessage("Successfully reset the password.")
      }).catch((err) => {
        setMessage(err.message || 'An error occurred.');
      });
    } else {
      setMessage('Passwords do not match. Please try again.');
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">New Password</h1>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password:
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password:
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </div>
          <button className="btn btn-primary" onClick={handleConfirmClick}>
            Confirm
          </button>
          <p className="mt-3">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default App;
